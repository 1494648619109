<template>
  <div>
    <v-row no-gutters align="center" style="height: 100vh">
      <v-col align-self="center" cols="12">
        <div v-if="showLoading">
          <loading-component />
        </div>
        <div v-else-if="tokenRegistrationError">
          <div v-if="isDesktop"><error-desktop /></div>
          <div v-else><error-mobile /></div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style>
.half-wrapper {
  width: 50%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-center {
  text-align: center;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import ErrorDesktop from "./components/error-desktop.vue";
import ErrorMobile from "./components/error-mobile.vue";

export default {
  name: "Token",
  components: { ErrorDesktop, ErrorMobile },
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters({
      tokenRegistrationError: "account/tokenRegistrationError",
      showLoading: "account/showLoading",
      selectedLocale: "account/getSelectedLocale",
    }),
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  methods: {
    ...mapActions({
      registerWithToken: "account/registerWithToken",
    }),
  },
  mounted() {
    let isJwt = this.$route.meta.isJwt;
    let token = this.$route.query.token;
    let path = this.$route.path;
    this.registerWithToken({ isJwt: isJwt, token: token, path: path});
  },
};
</script>